import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './AddBitrixChannelForm.css'

import FormStatus from '../FormStatus'
import Input from '../../formControls/Input'
import Button from '../../buttons/Button'

const AddBitrixChannelForm = ({ data, onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         username: ''
      },
      validationSchema: yup.object().shape({
         username: yup.string()
            .matches(/^[a-zA-Z0-9-+]+\.(bitrix24)\.(ru|by|kz|ua|tj|tm|kg|az|am|com|de|fr|es|it|pl|pt|nl|be|at|ch|se|dk|no|fi|ie|cz|hu|ro|bg|sk|hr|si|gr|cy|lt|lv|ee|mt|uz)$/, 'Некорректный домен. Пример: domain.bitrix24.ru')
            .required('Введите домен портала Битрикс24'),
      }),
      onSubmit: async ({ username }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            await onSubmit({
               username
            })
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   return (
      <form 
         className='add-bitrix-channel-form'
         onSubmit={formik.handleSubmit}   
      >
         {formik.status && <FormStatus text={formik.status} />}
         <Input
            label='Домен портала'
            id='username'
            name='username'
            value={formik.values.username}
            error={formik.touched.username && formik.errors.username}
            placeholder='store.bitrix24.ru'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
         />
         <Button
            className='add-bitrix-channel-form__submit-btn'
            type='submit'
            text='Добавить канал'
            disabled={disableSubmitBtn}
         />
      </form>
   )
}

export default AddBitrixChannelForm